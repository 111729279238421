/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    $.fn.scrollView = function() {
        return this.each(function() {
            $('html, body').animate({
                scrollTop: $(this).offset().top
            }, 500);
        });
    };
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            openDropDownMenu: function() {
                var $headerMenuBtn = $('#header-menu-btn');
                var $dropDownMenu = $('.drop-down-menu');
                $headerMenuBtn.click(function() {
                    $(this).toggleClass('header-menu-btn-selected');
                    $dropDownMenu.toggleClass('drop-down-menu-open');
                });
            },
            homeSlider: function() {
                var $homeCarousel = $('#home-carousel'),
                    $slideInfo = $('.slide-info'),
                    $featureColumn1 = $('.features-wrapper .feature-column-number-1'),
                    $featureColumn2 = $('.features-wrapper .feature-column-number-2'),
                    $featureColumn3 = $('.features-wrapper .feature-column-number-3');
                $homeCarousel.owlCarousel({
                    slideSpeed: 800,
                    paginationSpeed: 1500,
                    singleItem: true,
                    stopOnHover: true,
                    rewindSpeed: 0,
                    transitionStyle : "fade"
                });
                $homeCarousel.trigger('owl.play', 5000);
                $featureColumn2.hover(
                    function() {
                        $featureColumn1.find('.column-content').attr('style', 'border-right-color:transparent;');
                        $featureColumn3.find('.column-content').attr('style', 'border-left-color:transparent;');
                    },
                    function() {
                        $featureColumn1.find('.column-content').removeAttr('style');
                        $featureColumn3.find('.column-content').removeAttr('style');
                    }
                );
            },
            buttonUrlTrigger: function() {
                $('.enquiry-btn, .contact-btn').click(function() {
                    window.location = $(this).find('a').attr('href');
                });
            },
            memberSlider: function() {
                var $ourTeamSlider = $('.our-team-slider'),
                    $membersCarousel = $("#members-carousel"),
                    $memberNext = $ourTeamSlider.find('.slider-navigation .next'),
                    $memberPrev = $ourTeamSlider.find('.slider-navigation .prev');
                $membersCarousel.owlCarousel({
                    items: 5, //10 items above 1000px browser width
                    itemsDesktop: [1050, 4], //5 items between 1000px and 901px
                    itemsDesktopSmall: [767, 4], // betweem 900px and 601px
                    itemsTablet: [600, 2], //2 items between 600 and 0
                    itemsMobile: [375, 2], // itemsMobile disabled - inherit from itemsTablet option
                    pagination: false,
                    slideSpeed: 1000,
                    rewindSpeed: 1000
                });

                /* Members slider actions */
                $membersCarousel.find('.owl-item').each(function() {
                    $(this).click(function() {
                        var $item = $(this).find('.item'),
                            $siblingsItems = $(this).siblings().find('.item'),
                            $memberSlideAvatar = $item.find('.member-slide-avatar'),
                            $memberInfoToOpen = $('#member-info-id-' + $item.data('open'));
                        /* active state */
                        $item.addClass('member-slide-active');
                        $memberSlideAvatar.removeClass('gray-scale');
                        $memberInfoToOpen.addClass('member-info-active');
                        /* reset other siblings to inactive state */
                        $siblingsItems.removeClass('member-slide-active');
                        $siblingsItems.find('.member-slide-avatar').addClass('gray-scale');
                        $memberInfoToOpen.siblings().removeClass('member-info-active');
                    });
                });
                var owl = $membersCarousel.data('owlCarousel');
                $memberNext.click(function() {
                    owl.next();
                });
                $memberPrev.click(function() {
                    owl.prev();
                });
            },
            delayScroll: function(contentId) {
                setTimeout(function() {
                    $('#story-title-id-' + contentId).scrollView();
                }, 500);
            },
            storiesAction: function() {
                var self = this,
                    $storyAction = $('.story-action'),
                    $storyRow = $('.story-row');
                $storyAction.click(function() {
                    var $storyContentToOpen = $('#story-content-id-' + $(this).data('open'));
                    var $activeStoryTitle = $("#story-title-id-" + $(this).data('open'));
                    /* Open state for clicked story */
                    $(this).toggleClass('story-action-open');
                    $storyContentToOpen.toggleClass('story-open');
                    $activeStoryTitle.toggleClass('story-title-active');
                    /* Close other stories */
                    $storyRow.find('.story-action').not('#story-action-id-' + $(this).data('open')).removeClass('story-action-open');
                    $storyRow.find('.story-title').not('#story-title-id-' + $(this).data('open')).removeClass('story-title-active');
                    $storyContentToOpen.parent().siblings().find('.story-content').removeClass('story-open');
                    /* Scroll view to the open story */
                    self.delayScroll($(this).data('open'));
                });
            },
            smoothScrolling: function() {
                // Smooth Scrolling
                $('html').smoothScroll(800);
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                var self = this;
                self.smoothScrolling();
                self.openDropDownMenu();
                self.homeSlider();
                self.memberSlider();
                self.buttonUrlTrigger();
                self.storiesAction();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
